import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { color } from "../theme"

import SEO from "../components/seo"
import Layout, { CenterPage } from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HelpPost from "../components/HelpPost"
import IntroSection from "../components/IntroSection";

const HelpPostContainer = styled.div`
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${color.LIGHTER_GRAY};
  &:last-child {
    border-bottom: 0;
  }
`

const HelpfulPostsList = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout bgColor="LIGHTEST_GRAY">
      <SEO title="Helpful Parenting Posts on Twitter" />
      <Header bgColor="TEAL" />
      <IntroSection
        heading="Best Parenting Advice on Twitter"
      >
        <p>
          Here are some of the most helpful resources found on Twitter about parenting young children.
          We highlight the important take-aways and attempt to briefly explain how to apply them.
          Worth a read for more effective parenting, with or without the <a href="/">Goody Points app</a>!
        </p>
      </IntroSection>
      <CenterPage>
        {
          edges.map(edge => {
            const { id, html, fields, frontmatter } = edge.node
            if (frontmatter.post_type === 'helpful-parenting-post') {
              return (
                <HelpPostContainer key={id}>
                  <HelpPost post={frontmatter} slug={fields.slug} inList>
                    {html}
                  </HelpPost>
                </HelpPostContainer>
              )
            }
            return null;
          })
        }
      </CenterPage>
      <Footer bgColor="BLACK" />
    </Layout>
  )
}

export const query = graphql`
query HomepageQuery {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        fields {
          slug
        }
        id
        html
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          post_type
          tags
          quote
          cite
          credit
          attribution
          tweet {
            id
            entities {
              urls {
                expanded_url
                title
              }
            }
          }
        }
      }
    }
  }
}
`
export default HelpfulPostsList